import axios from "axios";

export const allStudents = async () => {
  const response = await fetch(
    "https://bc-recruiter-be-six.vercel.app/api/students/",
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return response.json();
};

export const getStudent = async ({ queryKey }) => {
  const [_, id] = queryKey;
  const { data } = await axios.get(
    `https://bc-recruiter-be-six.vercel.app/api/students/${id}`
  );
  return data;
};

export const addStudent = async ({
  firstname,
  lastname,
  email,
  password,
  phone,
  role,
  speciality1,
  speciality2,
  speciality3,
  avatar,
}) => {
  const response = await fetch(
    "https://bc-recruiter-be-six.vercel.app/api/students/",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        firstname: firstname,
        lastname: lastname,
        email: email,
        password: password,
        phone: phone,
        role: role,
        speciality1: speciality1,
        speciality2: speciality2,
        speciality3: speciality3,
        avatar: avatar,
      }),
    }
  );

  return response;
};

export const deleteStudent = async ({ id }) => {
  const response = await fetch(
    `https://bc-recruiter-be-six.vercel.app/api/students/${id}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return response;
};

export const editStudent = async ({
  id,
  firstname,
  lastname,
  speciality1,
  speciality2,
  speciality3,
  presentation,
  homepage,
  showreel,
  isApproved,
  isLocked,
}) => {
  const data = {
    firstname: firstname,
    lastname: lastname,
    speciality1: speciality1,
    speciality2: speciality2,
    speciality3: speciality3,
    presentation: presentation,
    homepage: homepage,
    showreel: showreel,
    isApproved: isApproved,
    isLocked: isLocked,
  };

  const response = await axios.patch(
    `https://bc-recruiter-be-six.vercel.app/api/students/${id}`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return response;
};

export const editStudentCourses = async ({ id, courses }) => {
  const data = {
    courses: courses,
  };

  const response = await axios.patch(
    `https://bc-recruiter-be-six.vercel.app/api/students/${id}/courses`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return response;
};

export const loginUser = async ({ email, password }) => {
  const authData = {
    email: email,
    password: password,
  };

  const response = await axios.post(
    `https://bc-recruiter-be-six.vercel.app/api/login/`,
    authData,
    {
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(authData),
    }
  );

  return response;
};

export const uploadUserAvatar = async ({ avatar, id }) => {
  const data = {
    avatar: avatar,
  };

  const response = await axios.patch(
    `https://bc-recruiter-be-six.vercel.app/api/student/${id}/avatar/`,
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      body: JSON.stringify(data),
    }
  );

  return response;
};

export const uploadUserResume = async ({ resume, id }) => {
  const data = {
    resume: resume,
  };

  const response = await axios.patch(
    `https://bc-recruiter-be-six.vercel.app/api/student/${id}/resume/`,
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      body: JSON.stringify(data),
    }
  );

  return response;
};

export const uploadUserCertificates = async ({ certificates, id }) => {
  const formData = new FormData();
  for (let i = 0; i < certificates.length; i++) {
    formData.append("certificates", certificates[i]);
  }

  const response = await axios.patch(
    `https://bc-recruiter-be-six.vercel.app/api/student/${id}/certificates/`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return response;
};

export const uploadUserBackground = async ({ backgroundImage, id }) => {
  const data = {
    backgroundImage: backgroundImage,
  };

  const response = await axios.patch(
    `https://bc-recruiter-be-six.vercel.app/api/student/${id}/backgroundImage/`,
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      body: JSON.stringify(data),
    }
  );

  return response;
};

export const deleteUserCertificate = async (cert) => {
  const response = await axios.delete(
    `https://bc-recruiter-be-six.vercel.app/api/student/${cert.id}/certificates/${cert.certificates}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  console.log(response, "res");

  return response;
};
