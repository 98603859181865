import axios from "axios";

export const getCompanies = async () => {
  const { data } = await axios.get(
    `https://bc-recruiter-be-six.vercel.app/api/companies/`
  );
  return data;
};

export const getCompany = async ({ queryKey }) => {
  const [_, id] = queryKey;
  const { data } = await axios.get(
    `https://bc-recruiter-be-six.vercel.app/api/companies/${id}`
  );
  return data;
};

export const editCompany = async ({
  id,
  firstname,
  lastname,
  phone,
  hidePhone,
  avatar,
}) => {
  const data = {
    firstname: firstname,
    lastname: lastname,
    phone: phone,
    avatar: avatar,
    hidePhone: hidePhone,
  };

  const response = await axios.patch(
    `https://bc-recruiter-be-six.vercel.app/api/companies/${id}`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return response;
};

export const uploadCompanyAvatar = async ({ avatar, id }) => {
  const data = {
    avatar: avatar,
  };

  const response = await axios.patch(
    `https://bc-recruiter-be-six.vercel.app/api/companies/${id}/avatar/`,
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      body: JSON.stringify(data),
    }
  );

  return response;
};

export const deleteCompany = async ({ id }) => {
  const response = await fetch(
    `https://bc-recruiter-be-six.vercel.app/api/companies/${id}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return response;
};

export const uploadUserBackground = async ({ backgroundImage, id }) => {
  const data = {
    backgroundImage: backgroundImage,
  };

  const response = await axios.patch(
    `https://bc-recruiter-be-six.vercel.app/api/student/${id}/backgroundImage/`,
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      body: JSON.stringify(data),
    }
  );

  return response;
};
