import { useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";

export const userAtom = atomWithStorage("user", null);

export const useSetUser = () => {
  const [, setUser] = useAtom(userAtom);

  return (userData) => {
    setUser(userData);
  };
};
