import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const Candidates = ({ candidates, hasLoading }) => {
  return (
    <div className="container gap-100">
      <div className="row my-4">
        {hasLoading && "Laddar"}
        {candidates &&
          candidates.data.map((candidate, index) => (
            <motion.div
              Layout
              animate={{ opacity: 1, scale: 1 }}
              initial={{ opacity: 0, scale: 0 }}
              exit={{ opacity: 0, scale: 0 }}
              div
              className="col-md-4"
            >
              <Link to={`/student/${candidate._id}`}>
                <div className="card profile-card-3 my-2">
                  <div className="background-block">
                    <img
                      src="bg.jpg"
                      alt="profile-sample1"
                      className="background"
                    />
                  </div>
                  <div className="profile-thumb-block">
                    <img
                      src={
                        candidate.avatar === ""
                          ? "fallback-img.png"
                          : candidate.avatar
                      }
                      className="profile"
                    />
                  </div>
                  <div className="card-content">
                    <h2>
                      {candidate.firstname} {candidate.lastname}
                      <small>Designer</small>
                    </h2>
                    <div className="icon-block">
                      <span>{candidate.speciality1}</span>
                      <span>{candidate.speciality2}</span>
                      <span>{candidate.speciality3}</span>
                    </div>
                  </div>
                </div>
              </Link>
            </motion.div>
          ))}
      </div>
    </div>
  );
};

export default Candidates;
