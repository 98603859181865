const NoAccess = () => {
  return (
    <>
      <div className="w3-display-middle my-5">
        <h1 className="w3-jumbo w3-animate-top w3-center">
          <code>Nekad tillträde</code>
        </h1>
        <hr
          className="w3-border-white w3-animate-left"
          style={{ margin: "auto", width: "50%" }}
        />
        <h3 className="w3-center w3-animate-right">
          Du har inte behörighet att se denna sida.
        </h3>
        <h3 className="w3-center w3-animate-zoom">🚫🚫🚫🚫</h3>
        <h6 className="w3-center w3-animate-zoom">403 forbidden</h6>
      </div>
    </>
  );
};

export default NoAccess;
