import { useMutation, useQueryClient } from "react-query";
import { useState } from "react";
import { addStudent } from "../requests/requests";
import { useAtom } from "jotai";
import { userAtom } from "../hooks/UserAuthorization";
import NoAccess from "./NoAccess";

const AddStudent = () => {
  const [user] = useAtom(userAtom);
  const [firstname, setFirstname] = useState();
  const [lastname, setLastname] = useState();
  const [speciality1, setSpeciality1] = useState();
  const [speciality2, setSpeciality2] = useState();
  const [speciality3, setSpeciality3] = useState();
  const [certificates, setCertificates] = useState([]);
  const [email, setEmail] = useState();
  const [role, setRole] = useState();
  const [password, setPassword] = useState();
  const queryClient = useQueryClient();

  const {
    mutate: newStudent,
    isSuccess,
    isLoading,
    isError,
    error,
  } = useMutation(addStudent, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("students");
    },
    onError: (error) => {
      console.error(error.message);
    },
  });

  const handlesubmit = (e) => {
    e.preventDefault();

    newStudent({
      firstname: firstname,
      lastname: lastname,
      email: email,
      password: password,
      role: role,
      speciality1: speciality1,
      speciality2: speciality2,
      speciality3: speciality3,
      certificates: certificates,
    });
  };

  if (user && user.role !== "admin") {
    return <NoAccess />;
  }

  return (
    <>
      <div className="container gap-100">
        <div className="row  g-3 my-5">
          <form className="row  g-3 my-5" onSubmit={handlesubmit}>
            {isSuccess ? (
              <div className="alert alert-primary my-5" role="alert">
                {firstname} {lastname} successfully created!
              </div>
            ) : (
              ""
            )}

            <h2 className="text-center my-4">Create new user</h2>
            <div className="col-md-12">
              <label className="form-label">Role</label>
              <select
                id="lang"
                onChange={(e) => setRole(e.target.value)}
                className="form-control"
              >
                <option value="">-Choose-</option>
                <option value="student">Candidate</option>
                <option value="company">Company</option>
              </select>
            </div>
            <div className="col-md-6">
              <label for="inputEmail4" className="form-label">
                {role === "student" ? "Firstname" : "Company name"}
              </label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => setFirstname(e.target.value)}
                required={true}
              />
            </div>
            {role === "student" ? (
              <div className="col-md-6">
                <label for="inputPassword4" className="form-label">
                  Lastname
                </label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setLastname(e.target.value)}
                  required={true}
                />
              </div>
            ) : (
              ""
            )}
            <div className="col-md-6">
              <label for="inputEmail4" className="form-label">
                Password
              </label>
              <input
                type="password"
                className="form-control"
                onChange={(e) => setPassword(e.target.value)}
                required={true}
              />
            </div>
            <div className="col-md-6">
              <label className="form-label">Confirm password</label>
              <input type="password" className="form-control" />
            </div>

            <div className="col-md-6">
              <label className="form-label">Email</label>
              <input
                type="email"
                className="form-control"
                onChange={(e) => setEmail(e.target.value)}
                required={true}
              />
            </div>

            {role === "student" && (
              <>
                <div className="col-md-4">
                  <label for="inputCity" className="form-label">
                    Speciality 1
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setSpeciality1(e.target.value)}
                  />
                </div>

                <div className="col-md-4">
                  <label for="inputCity" className="form-label">
                    Speciality 2
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setSpeciality2(e.target.value)}
                  />
                </div>

                <div className="col-md-4">
                  <label for="inputCity" className="form-label">
                    Speciality 3
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setSpeciality3(e.target.value)}
                  />
                </div>
              </>
            )}

            <div className="col-md-12">
              <button type="submit" className="button-primary">
                Save {isSuccess ? <i className="fa-solid fa-check"></i> : ""}
                {isLoading ? <i className="fas fa-sync fa-spin"></i> : ""}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddStudent;
