import { useMutation, useQueryClient, useQuery } from "react-query";
import { useState } from "react";
import {
  getCompany,
  editCompany,
  uploadCompanyAvatar,
  uploadUserBackground,
} from "../../requests/companies";
import { useParams } from "react-router-dom";
import { useAtom } from "jotai";
import { userAtom } from "../../hooks/UserAuthorization";

const EditCompany = () => {
  const [user] = useAtom(userAtom);
  const queryClient = useQueryClient();
  const [firstname, setFirstname] = useState();
  const [lastname, setLastname] = useState();
  const [phone, setPhone] = useState();
  const [avatar, setAvatar] = useState();
  const [hidePhone, setHidePhone] = useState();
  const [uploadedMsg, setUploadeMsg] = useState();
  const [personalBackground, setPersonalBackground] = useState();

  const { id } = useParams();
  const { data, isLoading } = useQuery(["id", id], getCompany);

  const {
    mutate: updatedCompany,
    isSuccess: hasSuccess,
    isLoading: hasLoading,
    isError: hasError,
    error,
  } = useMutation(editCompany, {
    onSuccess: () => {
      queryClient.invalidateQueries("companies");
    },
    onError: (error) => {
      console.error(error.message);
    },
  });

  const handlesubmit = (e) => {
    e.preventDefault();

    updatedCompany({
      id: id,
      firstname: firstname,
      lastname: lastname,
      phone: phone,
      hidePhone: hidePhone,
    });
  };

  const {
    mutate: addAvatar,
    isSuccess: gotSuccess,
    isLoading: gotLoading,
    isError: gotError,
    error: theError,
  } = useMutation(uploadCompanyAvatar, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("companies");
      setUploadeMsg(data.data.msg);
    },
    onError: (error) => {
      console.error(error.message);
    },
  });

  const uploadAtavar = async (e) => {
    e.preventDefault();

    addAvatar({
      id: id,
      avatar: avatar[0],
    });
  };

  const {
    mutate: addBackground,
    isSuccess: bgSuccess,
    isLoading: bgLoading,
    isError: bgError,
    error: bgTheError,
  } = useMutation(uploadUserBackground, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("students");
      setUploadeMsg(data.data.msg);
    },
    onError: (error) => {
      console.error(error.message);
    },
  });

  const uploadBackground = async (e) => {
    e.preventDefault();

    addBackground({
      id: id,
      backgroundImage: personalBackground[0],
    });
  };

  return (
    <div className="container gap-100">
      {data && (
        <>
          <form className="my-5" onSubmit={handlesubmit}>
            <div className="mb-3">
              <label className="form-label">Firstname</label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => setFirstname(e.target.value)}
                required={true}
                defaultValue={data.firstname}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Lastname</label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => setLastname(e.target.value)}
                required={true}
                defaultValue={data.lastname}
              />
            </div>

            <div className="mb-3">
              <label className="form-label">Phone</label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => setPhone(e.target.value)}
                required={true}
                defaultValue={data.phone}
              />
            </div>

            <div className="mb-3">
              <label className="form-label">Hide phoner?</label>{" "}
              <input
                type="checkbox"
                onChange={(e) => setHidePhone(e.target.checked)}
                defaultChecked={data.hidePhone}
              />{" "}
              Yes
            </div>

            <button type="submit" className="btn btn-primary">
              Update
            </button>

            <hr />
          </form>

          <form encType="multipart/form-data">
            <label htmlFor="" className="mb-2">
              Upload logotyp
            </label>
            <br />
            <input
              type="file"
              name="avatar"
              onChange={(e) => setAvatar(e.target.files)}
            />
            <br />
            <button
              type="submit"
              className="btn btn-primary mt-3 mb-5"
              onClick={uploadAtavar}
            >
              Upload
              {/* {isLoading ? <i className="fas fa-sync fa-spin"></i> : ""} */}
            </button>
          </form>

          <form encType="multipart/form-data" className="col-md-4">
            {bgSuccess && (
              <>
                <h5>{uploadedMsg}</h5>
              </>
            )}
            <label className="mb-2">Upload background image</label>
            <br />
            <input
              type="file"
              name="backgroundImage"
              onChange={(e) => setPersonalBackground(e.target.files)}
            />
            <br />
            <button
              type="submit"
              className="btn btn-primary mt-3 mb-5"
              onClick={uploadBackground}
            >
              Upload
              {bgLoading ? <i className="fas fa-sync fa-spin"></i> : ""}
            </button>
          </form>
        </>
      )}
    </div>
  );
};

export default EditCompany;
