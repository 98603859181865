import "./App.css";
import Navigation from "./components/Navigation";
import AddStudent from "./components/AddStudent";
import { QueryClient, QueryClientProvider } from "react-query";
import { HashRouter, Routes, Route } from "react-router-dom";
import AllStudents from "./components/AllStudents";
import AllCompanies from "./components/AllCompanies";
import ShowStudent from "./components/ShowStudent";
import EditStudent from "./components/EditStudent";
import AuthEditStudent from "./components/Auth/AuthEditStudent";
import Login from "./components/Login";
import Search from "./components/Search/Search";
import Company from "./components/Company/Company";
import EditCompany from "./components/Company/EditCompany";
import { PrivateRoute, RequireAuth } from "react-auth-kit";
import { Helmet } from "react-helmet";

const queryClient = new QueryClient();

function App() {
  return (
    <>
      <Helmet>
        <link
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css"
          rel="stylesheet"
          integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC"
          crossorigin="anonymous"
        />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.3.0/css/all.min.css"
          integrity="sha512-SzlrxWUlpfuzQ+pcUCosxcglQRNAq/DZjVsC0lE40xsADsfeQoEypE+enwcOiGjk/bSuGGKHEyjSoQ1zVisanQ=="
          crossorigin="anonymous"
          referrerpolicy="no-referrer"
        />

        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap"
          rel="stylesheet"
        />

        <script
          src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js"
          integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM"
          crossorigin="anonymous"
        ></script>
      </Helmet>

      <QueryClientProvider client={queryClient}>
        <HashRouter>
          <Navigation />
          <Routes>
            <Route path="/" element={<Login />} />
            <Route
              path="/students"
              element={
                <RequireAuth loginPath={"/login"}>
                  <AllStudents />
                </RequireAuth>
              }
            />
            <Route
              path="/companies"
              element={
                <RequireAuth loginPath={"/login"}>
                  <AllCompanies />
                </RequireAuth>
              }
            />
            <Route
              path="/student/:id"
              element={
                <RequireAuth loginPath={"/login"}>
                  <ShowStudent />
                </RequireAuth>
              }
            />
            <Route
              path="/edit-student/:id"
              element={
                <RequireAuth loginPath={"/login"}>
                  <EditStudent />
                </RequireAuth>
              }
            />

            <Route
              path="/auth/edit-student/:id"
              element={
                <RequireAuth loginPath={"/login"}>
                  <AuthEditStudent />
                </RequireAuth>
              }
            />

            <Route
              path="/auth/edit-company/:id"
              element={
                <RequireAuth loginPath={"/login"}>
                  <AuthEditStudent />
                </RequireAuth>
              }
            />
            <Route
              path="/search/"
              element={
                <RequireAuth loginPath={"/login"}>
                  <Search />
                </RequireAuth>
              }
            />
            <Route
              path={"/new-student"}
              element={
                <RequireAuth loginPath={"/login"}>
                  <AddStudent />
                </RequireAuth>
              }
            />
            <Route
              path={"/company/:id"}
              element={
                <RequireAuth loginPath={"/login"}>
                  <Company />
                </RequireAuth>
              }
            />
            <Route
              path={"/edit-company/:id"}
              element={
                <RequireAuth loginPath={"/login"}>
                  <EditCompany />
                </RequireAuth>
              }
            />
          </Routes>
        </HashRouter>
      </QueryClientProvider>
    </>
  );
}

export default App;
