import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getCompany } from "../../requests/companies";
import { MoonLoader } from "react-spinners";

const Company = () => {
  const { id } = useParams();
  const { data, isLoading, isError, isSuccess } = useQuery(
    ["id", id],
    getCompany
  );

  if (isLoading) {
    return (
      <div className="spinner">
        <MoonLoader color="#000" />
        <p className="my-2">Loading...</p>
      </div>
    );
  }

  return (
    <>
      <div className="container gap-100">
        {isSuccess && (
          <div className="row my-5">
            <div className="col-md-4 display-flex">
              <div className="company-cards">
                <h1 className="text-center">
                  {data.firstname} {data.lastname}
                </h1>
                <div className="compant-avatar-wrapper my-4">
                  <img
                    src={data.avatar}
                    alt=""
                    className="company-avatar my-3"
                  />
                </div>

                <div className="company-contact">
                  <div>
                    <p>
                      <i class="fa-solid fa-envelope"></i> {data.email}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Company;
