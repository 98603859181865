import { useQuery } from "react-query";
import { getStudent } from "../requests/requests";
import { useParams } from "react-router-dom";
import { MoonLoader } from "react-spinners";

const ShowStudent = () => {
  const { id } = useParams();
  const { data, isLoading, isError, isSuccess } = useQuery(
    ["id", id],
    getStudent
  );

  if (isLoading) {
    return (
      <>
        <div className="spinner">
          <MoonLoader color="#000" />
          <p className="my-2">Loading...</p>
        </div>
      </>
    );
  }

  if (isError) {
    return (
      <>
        <h3>Something went wrong...</h3>
      </>
    );
  }

  return (
    <div className="my-5 container gap-100">
      {isSuccess && (
        <div className="row">
          <div className="col-md-6 display-flex my-4">
            <div className="user-profile-card flexify h-full">
              <div>
                {" "}
                <h1>
                  {data.firstname} {data.lastname}
                </h1>
                <div className="my-4">
                  <img
                    src={data.avatar === "" ? "fallback-img.png" : data.avatar}
                    alt=""
                    className="userAvatar my-3"
                  />
                </div>
                <p>Email: {data.email}</p>
              </div>
            </div>
          </div>

          <div className="col-md-6 display-flex my-4">
            <div className="user-profile-card-info my-3 flexify">
              <div>
                <div className="mb-5">
                  <h6 className="profile-section">My CV</h6>
                  {data.resume !== "" ? (
                    <div>
                      <a href={data.resume} target="_blank">
                        <i className="fa-solid fa-file-pdf"></i>
                      </a>
                    </div>
                  ) : (
                    "CV not added yet.."
                  )}
                </div>

                <div className="mb-5">
                  <h6 className="profile-section">Specialities:</h6>
                  <ul className="specialties-list text-left">
                    {data.speciality1 && (
                      <li className="mx-3 specialities-listitem">
                        <i class="fa-solid fa-circle-check"></i>{" "}
                        <span className="mx-2">{data.speciality1}</span>
                      </li>
                    )}
                    {data.speciality2 && (
                      <li className="mx-3 specialities-listitem">
                        <i class="fa-solid fa-circle-check"></i>{" "}
                        <span className="mx-2"> {data.speciality2}</span>
                      </li>
                    )}
                    {data.speciality3 && (
                      <li className="mx-3 specialities-listitem">
                        {" "}
                        <i class="fa-solid fa-circle-check"></i>
                        <span className="mx-2"> {data.speciality3}</span>
                      </li>
                    )}
                  </ul>
                </div>

                {data.homepage && (
                  <div className="mb-5">
                    <h6 className="profile-section">Website</h6>{" "}
                    <p>{data.homepage}</p>
                  </div>
                )}
                {data.showreel && (
                  <div className="mb-5">
                    <h6 className="profile-section">Showreel</h6>
                    <p>{data.showreel}</p>
                  </div>
                )}

                {data.certificates.length > 0 && (
                  <div className="mb-5">
                    <div className="row">
                      <h6 className="profile-section">Certificates</h6>
                      {data.certificates.map((certificate, i) => (
                        <div className="col-md-2 my-2 mx-2">
                          <a href={certificate.url} target="_blank">
                            <img
                              className="certificate"
                              src={certificate.url}
                            />
                          </a>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="col-md-12 user-profile-card-info my-2">
            <h2>Presentation</h2>
            <p className="my-3">
              {data.presentation ? data.presentation : "Nothing to show yet.."}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShowStudent;
