import { useMutation, useQueryClient, useQuery } from "react-query";
import { useState } from "react";
import {
  editStudent,
  getStudent,
  uploadUserAvatar,
  uploadUserResume,
  uploadUserCertificates,
  editStudentCourses,
  uploadUserBackground,
} from "../requests/requests";
import { useParams } from "react-router-dom";
import { useAtom } from "jotai";
import { userAtom } from "../hooks/UserAuthorization";
import NoAccess from "./NoAccess";
import { getAllBrands } from "../requests/brands";

const EditStudent = () => {
  const [user] = useAtom(userAtom);
  const queryClient = useQueryClient();
  const [firstname, setFirstname] = useState();
  const [lastname, setLastname] = useState();

  const [speciality1, setSpeciality1] = useState();
  const [speciality2, setSpeciality2] = useState();
  const [speciality3, setSpeciality3] = useState();
  const [presentation, setPresentation] = useState();
  const [isLocked, setLocked] = useState();
  const [isApproved, setApproved] = useState();
  const [avatar, setAvatar] = useState();
  const [homepage, setHomepage] = useState();
  const [showreel, setShowReel] = useState();
  const [personalBackground, setPersonalBackground] = useState();
  const [certificates, setCertificates] = useState();
  const [resume, setResume] = useState();
  const [uploadedMsg, setUploadeMsg] = useState();
  const [candidateCourses, setCandidateCourses] = useState();
  const courses = [];

  const { id } = useParams();
  const { data, isLoading } = useQuery(["id", id], getStudent);

  const {
    data: allBrands,
    isLoading: whenLoading,
    isSuccess: whenSuccess,
    isError: whenError,
  } = useQuery("brands", getAllBrands);

  const {
    mutate: updatedStudent,
    isSuccess: hasSuccess,
    isLoading: hasLoading,
    isError: hasError,
    error,
  } = useMutation(editStudent, {
    onSuccess: () => {
      queryClient.invalidateQueries("students");
    },
    onError: (error) => {
      console.error(error.message);
    },
  });

  const handlesubmit = (e) => {
    e.preventDefault();

    updatedStudent({
      id: id,
      firstname: firstname,
      lastname: lastname,
      speciality1: speciality1,
      speciality2: speciality2,
      speciality3: speciality3,
      presentation: presentation,
      homepage: homepage,
      showreel: showreel,
      isLocked: isLocked,
      isApproved: isApproved,
      avatar: avatar,
      resume: resume,
    });
  };

  const {
    mutate: studentCourses,
    isSuccess: coursesSuccess,
    isLoading: coursesLoading,
    isError: coursesError,
    error: courseError,
  } = useMutation(editStudentCourses, {
    onSuccess: () => {
      queryClient.invalidateQueries("students");
    },
    onError: (error) => {
      console.error(error.message);
    },
  });

  const applyCourses = (e) => {
    e.preventDefault();

    if (e.target.checked) {
      courses.push(e.target.value);
    } else {
      const index = courses.findIndex((course) => course === e.target.value);
      courses.splice(index, 1);
    }
  };

  const handleCourses = (e) => {
    e.preventDefault();

    studentCourses({
      id: id,
      courses: courses,
    });
  };

  const {
    mutate: addAvatar,
    isSuccess: gotSuccess,
    isLoading: gotLoading,
    isError: gotError,
    error: theError,
  } = useMutation(uploadUserAvatar, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("students");
      setUploadeMsg(data.data.msg);
    },
    onError: (error) => {
      console.error(error.message);
    },
  });

  const uploadAtavar = async (e) => {
    e.preventDefault();

    addAvatar({
      id: id,
      avatar: avatar[0],
    });
  };

  const {
    mutate: addResume,
    isSuccess: haveSuccess,
    isLoading: haveLoading,
    isError: haveError,
    error: TheError,
  } = useMutation(uploadUserResume, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("students");
      setUploadeMsg(data.data.msg);
    },
    onError: (error) => {
      console.error(error.message);
    },
  });

  const uploadResume = async (e) => {
    e.preventDefault();

    addResume({
      id: id,
      resume: resume[0],
    });
  };

  const {
    mutate: addBackground,
    isSuccess: bgSuccess,
    isLoading: bgLoading,
    isError: bgError,
    error: bgTheError,
  } = useMutation(uploadUserBackground, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("students");
      setUploadeMsg(data.data.msg);
    },
    onError: (error) => {
      console.error(error.message);
    },
  });

  const uploadBackground = async (e) => {
    e.preventDefault();

    addBackground({
      id: id,
      backgroundImage: personalBackground[0],
    });
  };

  const {
    mutate: addCertificates,
    isSuccess: atSuccess,
    isLoading: atLoading,
    isError: atError,
    error: atTheError,
  } = useMutation(uploadUserCertificates, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("students");
      setUploadeMsg(data.data.msg);
    },
    onError: (error) => {
      console.error(error.message);
    },
  });

  const uploadCertificates = async (e) => {
    e.preventDefault();

    addCertificates({
      id: id,
      certificates: certificates,
    });
  };

  if (user && user.id !== id) {
    return <NoAccess />;
  }

  return (
    <div className="container gap-100">
      {data &&
        data.courses.map((val) => {
          courses.push(val);
        })}

      {data && (
        <>
          <h1 className="text-center my-5">My account</h1>
          <div className="text-center">
            <img src={data.avatar} alt="" className="userAvatar" />
          </div>
          <form className="my-5 row" onSubmit={handlesubmit}>
            {hasSuccess ? (
              <div className="alert alert-primary my-5" role="alert">
                {data.firstname} {data.lastname} updated successfully!
              </div>
            ) : (
              ""
            )}
            <div className="mb-3 col-md-4">
              <label className="form-label">Firstname</label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => setFirstname(e.target.value)}
                required={true}
                defaultValue={data.firstname}
              />
            </div>
            <div className="mb-3 col-md-4">
              <label className="form-label">Lastname</label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => setLastname(e.target.value)}
                required={true}
                defaultValue={data.lastname}
              />
            </div>

            <div className="mb-3 col-md-4">
              <label className="form-label">Specialty 1</label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => setSpeciality1(e.target.value)}
                defaultValue={data.speciality1}
                maxLength={50}
              />
            </div>

            <div className="mb-3 col-md-4">
              <label className="form-label">Specialty 2</label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => setSpeciality2(e.target.value)}
                defaultValue={data.speciality2}
                maxLength={50}
              />
            </div>

            <div className="mb-3 col-md-4">
              <label className="form-label">Specialty 3</label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => setSpeciality3(e.target.value)}
                defaultValue={data.speciality3}
                maxLength={50}
              />
            </div>

            {user && user.role === "admin" && (
              <>
                <div className="mb-3">
                  <label className="form-label">Lock account? </label>{" "}
                  <input
                    type="checkbox"
                    onChange={(e) => setLocked(e.target.checked)}
                    defaultChecked={data.isLocked}
                  />{" "}
                  Yes
                </div>
                <div className="mb-3">
                  <label className="form-label">Approve konto?</label>{" "}
                  <input
                    type="checkbox"
                    onChange={(e) => setApproved(e.target.checked)}
                    defaultChecked={data.isApproved}
                  />{" "}
                  Yes
                </div>
              </>
            )}

            <div className="mb-3 col-md-6">
              <label className="form-label">Website</label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => setHomepage(e.target.value)}
                defaultValue={data.homepage}
                maxLength={50}
              />
            </div>

            <div className="mb-3 col-md-6">
              <label className="form-label">Showreel</label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => setShowReel(e.target.value)}
                defaultValue={data.showreel}
                maxLength={50}
              />
            </div>

            <div className="mb-3">
              <label className="form-label">
                Presentation (Maximum 300 characters)
              </label>
              <textarea
                className="form-control"
                onChange={(e) => setPresentation(e.target.value)}
                defaultValue={data.presentation}
                maxLength={300}
                rows="5"
                cols="50"
              />
            </div>

            <div className="col-md-3">
              <button type="submit" className="btn btn-primary">
                Update
                {isLoading ? <i className="fas fa-sync fa-spin"></i> : ""}
              </button>
            </div>
          </form>

          <div className="row">
            <form encType="multipart/form-data" className="col-md-4">
              {bgSuccess && (
                <>
                  <h5>{uploadedMsg}</h5>
                </>
              )}
              <label className="mb-2">Upload background image</label>
              <br />
              <input
                type="file"
                name="backgroundImage"
                onChange={(e) => setPersonalBackground(e.target.files)}
              />
              <br />
              <button
                type="submit"
                className="btn btn-primary mt-3 mb-5"
                onClick={uploadBackground}
              >
                Upload
                {bgLoading ? <i className="fas fa-sync fa-spin"></i> : ""}
              </button>
            </form>

            <form encType="multipart/form-data" className="col-md-4">
              {haveSuccess && (
                <>
                  <h5>{uploadedMsg}</h5>
                </>
              )}
              <label htmlFor="" className="mb-2">
                Upload CV
              </label>
              <br />
              <input
                type="file"
                name="resume"
                onChange={(e) => setResume(e.target.files)}
              />
              <br />
              <button
                type="submit"
                className="btn btn-primary mt-3 mb-5"
                onClick={uploadResume}
              >
                Upload
                {haveLoading ? <i className="fas fa-sync fa-spin"></i> : ""}
              </button>
            </form>

            <form encType="multipart/form-data" className="col-md-4">
              {gotSuccess && (
                <>
                  <h5>{uploadedMsg}</h5>
                </>
              )}
              <label htmlFor="" className="mb-2">
                Upload avatar
              </label>
              <br />
              <input
                type="file"
                name="avatar"
                onChange={(e) => setAvatar(e.target.files)}
              />
              <br />
              <button
                type="submit"
                className="btn btn-primary mt-3 mb-5"
                onClick={uploadAtavar}
              >
                Upload
                {isLoading ? <i className="fas fa-sync fa-spin"></i> : ""}
              </button>
            </form>
          </div>
        </>
      )}
    </div>
  );
};

export default EditStudent;
