import { useMutation, useQueryClient, useQuery } from "react-query";
import { useEffect, useState } from "react";
import {
  editStudent,
  getStudent,
  uploadUserAvatar,
  uploadUserResume,
  uploadUserCertificates,
  deleteUserCertificate,
  editStudentCourses,
} from "../../requests/requests";
import { useParams } from "react-router-dom";
import { useAtom } from "jotai";
import { userAtom } from "../../hooks/UserAuthorization";
import NoAccess from "../NoAccess";
import { getAllBrands } from "../../requests/brands";
import { MoonLoader } from "react-spinners";

const AuthEditStudent = () => {
  const [user] = useAtom(userAtom);
  const queryClient = useQueryClient();
  const [firstname, setFirstname] = useState();
  const [lastname, setLastname] = useState();
  const [speciality1, setSpeciality1] = useState();
  const [speciality2, setSpeciality2] = useState();
  const [speciality3, setSpeciality3] = useState();
  const [isLocked, setLocked] = useState();
  const [isApproved, setApproved] = useState();
  const [avatar, setAvatar] = useState();
  const [certificates, setCertificates] = useState();
  const [resume, setResume] = useState();
  const [uploadedMsg, setUploadeMsg] = useState();
  const [candidateCourses, setCandidateCourses] = useState();
  const [showModal, setShowModal] = useState(false);
  const [cert_id, setCertId] = useState();
  const courses = [];

  const { id } = useParams();
  const { data, isLoading } = useQuery(["id", id], getStudent);

  const {
    data: allBrands,

    isSuccess: whenSuccess,
  } = useQuery("brands", getAllBrands);

  const { mutate: updatedStudent, isSuccess: hasSuccess } = useMutation(
    editStudent,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("students");
      },
      onError: (error) => {
        console.error(error.message);
      },
    }
  );

  const handlesubmit = (e) => {
    e.preventDefault();

    updatedStudent({
      id: id,
      firstname: firstname,
      lastname: lastname,
      speciality1: speciality1,
      speciality2: speciality2,
      speciality3: speciality3,
      isLocked: isLocked,
      isApproved: isApproved,
      resume: resume,
    });
  };

  const { mutate: studentCourses } = useMutation(editStudentCourses, {
    onSuccess: () => {
      queryClient.invalidateQueries("students");
    },
    onError: (error) => {
      console.error(error.message);
    },
  });

  const applyCourses = (e) => {
    e.preventDefault();

    if (e.target.checked) {
      courses.push(e.target.value);
    } else {
      const index = courses.findIndex((course) => course === e.target.value);
      courses.splice(index, 1);
    }
  };

  const handleCourses = (e) => {
    e.preventDefault();

    studentCourses({
      id: id,
      courses: courses,
    });
  };

  const { mutate: addAvatar, isSuccess: gotSuccess } = useMutation(
    uploadUserAvatar,
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("students");
        setUploadeMsg(data.data.msg);
      },
      onError: (error) => {
        console.error(error.message);
      },
    }
  );

  const uploadAtavar = async (e) => {
    e.preventDefault();

    if (avatar) {
      addAvatar({
        id: id,
        avatar: avatar[0],
      });
    }
  };

  const {
    mutate: addResume,
    isSuccess: haveSuccess,
    isLoading: haveLoading,
  } = useMutation(uploadUserResume, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("students");
      setUploadeMsg(data.data.msg);
    },
    onError: (error) => {
      console.error(error.message);
    },
  });

  const uploadResume = async (e) => {
    e.preventDefault();

    addResume({
      id: id,
      resume: resume[0],
    });
  };

  const {
    mutate: addCertificates,
    isSuccess: atSuccess,
    isLoading: atLoading,
  } = useMutation(uploadUserCertificates, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("certificates");
      setUploadeMsg(data.data.msg);
    },
    onError: (error) => {
      console.error(error.message);
    },
  });

  const uploadCertificates = async (e) => {
    e.preventDefault();

    addCertificates({
      id: id,
      certificates: certificates,
    });
  };

  const {
    mutate: removeCertificate,
    isSuccess: certSuccess,
    isLoading: certLoading,
    isError: certError,
    error: certTheError,
  } = useMutation(deleteUserCertificate, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("certificates");
      setUploadeMsg(data.data.msg);
    },
    onError: (error) => {
      console.error(error.message);
    },
  });

  const deleteCertificate = async (e) => {
    setCertId(e.target.id);
    setShowModal(true);
  };

  if (isLoading) {
    return (
      <div className="spinner">
        <MoonLoader color="#000" />
        <p className="my-2">Loading...</p>
      </div>
    );
  }

  if (user && user.role !== "admin") {
    return <NoAccess />;
  }

  return (
    <div className="container">
      {showModal && (
        <>
          <div
            className="modal fade"
            id="removeCertificate"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">
                    Do you really want to delete the certificate?
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                    onClick={(e) =>
                      removeCertificate({
                        id: id,
                        certificates: cert_id,
                      })
                    }
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <div className="row">
        {data &&
          data.courses.map((val) => {
            courses.push(val);
          })}

        {data && (
          <>
            <form className="my-5" onSubmit={handlesubmit}>
              {hasSuccess ? (
                <div className="alert alert-primary my-5" role="alert">
                  {firstname} {lastname} updated successfully!
                </div>
              ) : (
                ""
              )}
              <div className="mb-3">
                <label className="form-label">Firstname</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setFirstname(e.target.value)}
                  required={true}
                  defaultValue={data.firstname}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Lastname</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setLastname(e.target.value)}
                  required={true}
                  defaultValue={data.lastname}
                />
              </div>

              {user && user.role === "student" && (
                <>
                  <div className="mb-3">
                    <label className="form-label">Specialty 1</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => setSpeciality1(e.target.value)}
                      defaultValue={data.speciality1}
                    />
                  </div>

                  <div className="mb-3">
                    <label className="form-label">Specialty 2</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => setSpeciality2(e.target.value)}
                      defaultValue={data.speciality2}
                    />
                  </div>

                  <div className="mb-3">
                    <label className="form-label">Specialty 3</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => setSpeciality3(e.target.value)}
                      defaultValue={data.speciality3}
                    />
                  </div>
                </>
              )}
              {user && user.role === "admin" && (
                <>
                  <div className="mb-3">
                    <label className="form-label">Lock account? </label>{" "}
                    <input
                      type="checkbox"
                      onChange={(e) => setLocked(e.target.checked)}
                      defaultChecked={data.isLocked}
                    />{" "}
                    Yes
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Approve account?</label>{" "}
                    <input
                      type="checkbox"
                      onChange={(e) => setApproved(e.target.checked)}
                      defaultChecked={data.isApproved}
                    />{" "}
                    Yes
                  </div>
                </>
              )}

              <button type="submit" className="button-primary">
                Update
                {isLoading ? <i className="fas fa-sync fa-spin"></i> : ""}
              </button>
            </form>
            <hr />
            <div>
              <form encType="multipart/form-data">
                {haveSuccess && (
                  <>
                    <h5>{uploadedMsg}</h5>
                  </>
                )}
                <label htmlFor="" className="mb-2">
                  Upload CV
                </label>
                <br />
                <input
                  type="file"
                  name="resume"
                  onChange={(e) => setResume(e.target.files)}
                />
                <br />
                <button
                  type="submit"
                  className="button-primary mt-3 mb-5"
                  onClick={uploadResume}
                >
                  Upload
                  {haveLoading ? <i className="fas fa-sync fa-spin"></i> : ""}
                </button>
              </form>

              {user && user.role === "admin" && (
                <div>
                  <form encType="multipart/form-data">
                    {atSuccess && (
                      <>
                        <h5>{uploadedMsg}</h5>
                      </>
                    )}
                    <label htmlFor="" className="mb-2">
                      Upload certificate
                    </label>
                    <br />
                    <input
                      type="file"
                      name="certificates"
                      multiple
                      onChange={(e) => setCertificates(e.target.files)}
                    />
                    <br />
                    <button
                      type="submit"
                      className="button-primary mt-3 mb-5"
                      onClick={uploadCertificates}
                    >
                      Upload
                      {atLoading ? <i className="fas fa-sync fa-spin"></i> : ""}
                    </button>
                  </form>
                  <div className="certs">
                    {data.certificates.map((cert, i) => (
                      <>
                        <div className="grid" key={i}>
                          <img
                            src={cert.url}
                            alt=""
                            className="certificate mx-4"
                          />
                          <i
                            class="fa-solid fa-trash"
                            id={cert._id}
                            onClick={(e) => deleteCertificate(e)}
                            data-bs-toggle="modal"
                            data-bs-target="#removeCertificate"
                          ></i>
                        </div>
                      </>
                    ))}
                  </div>
                  <hr />
                </div>
              )}

              <form encType="multipart/form-data">
                {gotSuccess && (
                  <>
                    <h5>{uploadedMsg}</h5>
                  </>
                )}
                <label htmlFor="" className="mb-2">
                  Upload avatar
                </label>
                <br />
                <input
                  type="file"
                  name="avatar"
                  onChange={(e) => setAvatar(e.target.files)}
                />
                <br />
                <button
                  type="submit"
                  className="button-primary mt-3 mb-5"
                  onClick={uploadAtavar}
                >
                  Upload
                  {isLoading ? <i className="fas fa-sync fa-spin"></i> : ""}
                </button>
              </form>
            </div>

            {user && user.role === "admin" && (
              <div className="row my-5">
                <h3 className="my-4">Avklarade kurser</h3>
                <form className="row">
                  {whenSuccess &&
                    allBrands.data.map((brand, index) => (
                      <div className="col-md-3" key={index}>
                        <h5>{brand.name}</h5>
                        {brand &&
                          brand.products.map((prod, i) => (
                            <div className="checkbox-wrapper">
                              <label className="mx-2">{prod}</label>
                              <input
                                type="checkbox"
                                value={prod}
                                defaultChecked={
                                  data.courses.includes(prod) ? "checked" : ""
                                }
                                onChange={(e) => applyCourses(e)}
                              />
                            </div>
                          ))}
                      </div>
                    ))}
                  <div className="col-md-12 my-4">
                    <button className="button-primary" onClick={handleCourses}>
                      Update
                    </button>
                  </div>
                </form>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default AuthEditStudent;
