import { useState } from "react";
import { useQuery } from "react-query";
import { getBrand, getStudentByCourses } from "../../requests/brands";
import { useMutation, useQueryClient } from "react-query";
import Candidates from "./Candidates";
import { AnimatePresence } from "framer-motion";

const Search = () => {
  const queryClient = useQueryClient();
  const [brandName, setBrand] = useState();
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [candidates, setCandidates] = useState();

  const { data, refetch, isLoading, isError, isSuccess } = useQuery(
    ["name", brandName],
    getBrand,
    { enabled: false }
  );

  const {
    mutate: getStudentsByCourses,
    isSuccess: hasSuccess,
    isLoading: hasLoading,
    isError: hasError,
    error,
  } = useMutation(getStudentByCourses, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("candidates");
      setCandidates(data);
    },
    onError: (error) => {
      console.error(error.message);
    },
  });

  if (data && data === undefined) {
    return "undefined";
  }

  if (hasError) {
    return "Fel";
  }

  const getByCourses = async (e) => {
    e.preventDefault();
    console.log(selectedCourses);

    getStudentsByCourses({
      courses: selectedCourses,
    });
  };

  const addProducts = (e) => {
    const courseName = e.target.name;
    const isChecked = e.target.checked;

    setSelectedCourses((prevSelectedCourses) => {
      if (isChecked) {
        return [...prevSelectedCourses, courseName];
      } else {
        return prevSelectedCourses.filter((course) => course !== courseName);
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setBrand(e.target.value);
    setTimeout(() => {
      refetch();
    }, 100);
  };

  return (
    <>
      <div className="container">
        <form className="row">
          <h2 className="text-center my-5">Search Candidates</h2>

          <div className="col-md-2">
            <label className="form-label">Company</label>
            <select
              id="lang"
              onChange={(e) => handleSubmit(e)}
              className="form-control"
            >
              <option value="">-Choose-</option>
              <option value="apple">Apple</option>
              <option value="davinci">DaVinci</option>
              <option value="adobe">ADOBE</option>
              <option value="certnexus">CertNexus</option>
              <option value="avid">AVID</option>
              <option value="unreal">Unreal</option>
              <option value="unity">Unity</option>
              <option value="belicensed">BeLicensed</option>
            </select>
          </div>
        </form>
      </div>

      <div className="container">
        <div className="row my-5">
          {data &&
            data.products.map((prod, index) => (
              <div className="col-md-4" key={index}>
                <div>
                  <label className="form-label mx-1">{prod}</label>
                  <input
                    type="checkbox"
                    name={prod}
                    defaultValue={prod}
                    onChange={(e) => addProducts(e)}
                  />
                </div>
              </div>
            ))}
        </div>
        <button
          className="my-2 button-primary"
          onClick={(e) => getByCourses(e)}
        >
          Search
        </button>
      </div>

      <AnimatePresence>
        <Candidates candidates={candidates} hasLoading={hasLoading} />
      </AnimatePresence>
    </>
  );
};

export default Search;
