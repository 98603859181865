import { Link } from "react-router-dom";
import { useIsAuthenticated, useSignOut, useSignIn } from "react-auth-kit";
import { useAtom } from "jotai";
import { userAtom } from "../hooks/UserAuthorization";

const Navigation = () => {
  const [user] = useAtom(userAtom);
  const isAuthenticated = useIsAuthenticated();
  const signOut = useSignOut();
  const signIn = useSignIn();

  return (
    <>
      {isAuthenticated() ? (
        <div className="container-fluid m-0 p-0">
          <nav className="navbar navbar-expand-lg  navbar-dark bg-dark">
            <div className="container-fluid mt-3-mob">
              <a className="navbar-brand" href="#">
                <img
                  src="/logo.png"
                  className="nav-logo"
                  width="30"
                  height="24"
                />
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav">
                  {user && user.role == "admin" && (
                    <>
                      <li className="nav-item">
                        <Link to="/students" className="nav-link active">
                          All Candidates
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/companies" className="nav-link active">
                          All Companies
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link to="/new-student" className="nav-link active">
                          Create new user
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link to="/search" className="nav-link active">
                          Search
                        </Link>
                      </li>
                    </>
                  )}

                  {user && user.role == "student" && (
                    <li className="nav-item">
                      <Link
                        to={`/edit-student/${user.id}`}
                        className="nav-link active"
                      >
                        My Account
                      </Link>
                    </li>
                  )}
                  {user && user.role == "company" && (
                    <>
                      <li className="nav-item">
                        <Link to="/students" className="nav-link active">
                          All Candidates
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to={`/edit-company/${user.id}`}
                          className="nav-link active"
                        >
                          My Account
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link to="/search" className="nav-link active">
                          Search
                        </Link>
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </div>
            {isAuthenticated() ? (
              <>
                <Link
                  to="/"
                  className="nav-link active btn btn-danger logout-button"
                  onClick={() => signOut()}
                >
                  <i class="fa-solid fa-right-from-bracket"></i> Logout
                </Link>

                {user && (
                  <div className="signedInUser">
                    <Link
                      to={
                        user.role === "company"
                          ? `/company-account/${user.id}`
                          : `/student/${user.id}`
                      }
                      className="nav-link loggedin"
                    >
                      <i className="fa-regular fa-user"></i>
                      <span>{user && user.name}</span>
                    </Link>
                  </div>
                )}
              </>
            ) : (
              ""
            )}
          </nav>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Navigation;
