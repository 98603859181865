const Footer = () => {
  return (
    <footer class="bg-black text-center text-white">
      {/* <div class="p-4 pb-0">
        <section class="mb-4">
          <div className="logos-wrapper">
            <div class="m-2">
              <img className="footer-logo" src="BITBULLZ.png" alt="" />
            </div>
            <div class="m-2">
              <img className="footer-logo" src="DragonisGames.png" alt="" />
            </div>
            <div class="m-2">
              <img className="footer-logo" src="HeroJourney1.png" alt="" />
            </div>
            <div class="m-2">
              <img className="footer-logo" src="IceToadStudio.png" alt="" />
            </div>
            <div class="m-2">
              <img className="footer-logo" src="Macrocosm.png" alt="" />
            </div>
            <div class="m-2">
              <img className="footer-logo" src="NoemaGames.png" alt="" />
            </div>
            <div class="m-2">
              <img className="footer-logo" src="PurpleCowStudio.png" alt="" />
            </div>
            <div class="m-2">
              <img className="footer-logo" src="UnderdogVirtual.png" alt="" />
            </div>
          </div>
        </section>
      </div> */}

      <div
        class="text-center p-3"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
      >
        <div>
          <p> © 2024 Copyright</p>
          <a class="text-white" href="https://www.en.be-licensed.com/">
            be-licensed.com
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
