import axios from "axios";

export const getBrand = async ({ queryKey }) => {
  const [_, brandName] = queryKey;
  const { data } = await axios.get(
    `https://bc-recruiter-be-six.vercel.app/api/brands/${brandName}`
  );

  return data;
};

export const getStudentByCourses = async ({ courses }) => {
  const data = {
    courses: courses,
  };

  const response = await axios.post(
    `https://bc-recruiter-be-six.vercel.app/api/brands/courses/`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return response;
};

export const getAllBrands = async () => {
  const response = await axios.get(
    `https://bc-recruiter-be-six.vercel.app/api/brands/`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return response;
};
