import { useQuery, useMutation, useQueryClient } from "react-query";
import { getCompanies, deleteCompany } from "../requests/companies";
import { Link } from "react-router-dom";
import { useAtom } from "jotai";
import { userAtom } from "../hooks/UserAuthorization";
import { MoonLoader } from "react-spinners";
import { useState } from "react";
import NoAccess from "./NoAccess";

const AllCompanies = () => {
  const [user] = useAtom(userAtom);
  const queryClient = useQueryClient();
  const [showModal, setShowModal] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState();
  const [company, setCompany] = useState();

  const { data, isLoading, isError, isSuccess } = useQuery(
    "companies",
    getCompanies
  );

  const {
    mutate: removeCompany,
    isSuccess: certSuccess,
    isLoading: certLoading,
    isError: certError,
    error: certTheError,
  } = useMutation(deleteCompany, {
    onSuccess: () => {
      queryClient.invalidateQueries("companies");
    },
    onError: (error) => {
      console.error(error.message);
    },
  });

  if (isError) {
    return <>Något gick fel</>;
  }

  if (isLoading) {
    return (
      <div className="spinner">
        <MoonLoader color="#000" />
        <p className="my-2">Loading...</p>
      </div>
    );
  }

  const destroyCompany = (e) => {
    setShowModal(true);
  };

  if (user && user.role !== "admin") {
    return <NoAccess />;
  }
  return (
    <div className="container gap-100">
      <div className="row my-5">
        <h1 className="text-center mb-5">Companies</h1>
        {showModal && (
          <>
            <div
              class="modal fade"
              id="removeCandidate"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">
                      Do you really want to delete {company.firstname}{" "}
                      {company.lastname}
                    </h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>

                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      class="btn btn-primary"
                      data-bs-dismiss="modal"
                      onClick={() =>
                        removeCompany({
                          id: company._id,
                        })
                      }
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <div className="section_our_solution">
          <div className="row">
            {data &&
              data.map((company, index) => (
                <>
                  <div className="col-lg-4 col-md-6 col-sm-12 mb-4" key={index}>
                    <Link to={`/company/${company._id}`}>
                      <div className="card profile-card-3 mb-3">
                        <div className="background-block">
                          {company.backgroundImage !== "" ? (
                            <img
                              src={company.backgroundImage}
                              alt="profile-sample1"
                              className="background"
                            />
                          ) : (
                            <img
                              src="bg.jpg"
                              alt="profile-sample1"
                              className="background"
                            />
                          )}
                        </div>

                        <div className="profile-thumb-block">
                          <img
                            src={
                              company.avatar === ""
                                ? "fallback-img.png"
                                : company.avatar
                            }
                            className="profile"
                          />
                        </div>
                        <div className="card-content">
                          <h2>
                            {company.firstname} {company.lastname}
                          </h2>

                          <div>
                            {user && user.role == "admin" && (
                              <>
                                <Link to={`/auth/edit-company/${company._id}`}>
                                  <button
                                    type="button"
                                    className="edit_btn my-4"
                                  >
                                    Edit
                                  </button>
                                </Link>

                                <Link>
                                  {" "}
                                  <button
                                    type="button"
                                    id={company._id}
                                    className="button-primary"
                                    onClick={(e) => {
                                      destroyCompany(e);
                                      setCompany(company);
                                    }}
                                    data-bs-toggle="modal"
                                    data-bs-target="#removeCandidate"
                                  >
                                    Delete
                                  </button>
                                </Link>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllCompanies;
